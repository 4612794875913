import React from "react";
import { Button } from 'primereact/button';

export const BaseConhecimentoButton = ({ url, label, variant = "p-button", type = "button", tooltip, tooltipOptions, icon, style }) => {
    const handleClick = async (e) => {
        e.preventDefault();

        const apiKey = localStorage.getItem("apiKey") || "";
        const cliente = localStorage.getItem("cliente");
        const login = localStorage.getItem("login");

        try {
            const response = await fetch(`https://base-conhecimento.osm.com.br/api/gerar-token?apiKey=${apiKey}&cliente=${cliente}&login=${login}`);
            const data = await response.json();

            if (data.token) {
                const fullUrl = `${url}&apiKey=${apiKey}&token=${data.token}`;
                window.open(fullUrl, "_blank");
            } else {
                alert("Erro ao gerar token");
            }
        } catch (error) {
            console.error("Erro ao obter o token:", error);
            alert("Erro ao obter o token");
        }
    };

    return type === "link" ? (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
            style={{ textDecoration: 'underline', color: 'blue' }}
        >
            {label}
        </a>
    ) : (
        <Button className={variant} label={label} onClick={handleClick} {...(tooltip && { tooltip })} {...(tooltipOptions && { tooltipOptions })} {...(icon && { icon })} {...(style && { style })} />
    );
};

export default BaseConhecimentoButton;
